import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Page from '../../../component/Page';
import Panel from '../../../component/Panel';
import Modal from '../../../component/Modal';
import SortTable from '../../../component/SortTable';
import apiClient from '../../../service/apiClient';
import CardDoctorForm from '../../../component/form/CardDoctor';
import PanelAction from '../../../component/PanelAction';
import {sortBy} from '../../../service/util';
import dayjs from 'dayjs';
import {actions} from '../../../component/Snackbar/slice';
import {SNACKBAR_TYPE_DANGER, SNACKBAR_TYPE_SUCCESS} from '../../../component/Snackbar';
import {useAppDispatch} from '../../../module/redux/hooks';

const CardDoctor = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState<boolean>(true);
    const [doctors, setDoctors] = useState<KmrCategory[]>([]);
    const [records, setRecords] = useState<DoctorRecord[]>([]);
    const [openedRecord, setOpenedRecord] = useState(undefined);
    const [selectedRecords, setSelectedRecords] = useState<DoctorRecord[]>([]);

    async function fetchDoctors() {
        try {
            const tmp = await apiClient.getDoctors();

            setDoctors(tmp);
        } catch (e) {
            setDoctors([]);
        }
    }

    async function fetchRecords() {
        setLoading(true);

        try {
            const tmp = await apiClient.getRecords('doctor') as DoctorRecord[];

            setRecords(sortBy(tmp, 'record_at', true));
        } catch (e) {
            setRecords([]);
        }

        setLoading(false);
    }

    async function saveRecord(record: DoctorRecord, delFiles: RecordAttachment[], newFiles: RecordAttachment[]): Promise<boolean> {
        try {
            let data: DoctorRecord;

            if (record.id) {
                data = await apiClient.updateRecord('doctor', record) as DoctorRecord;
            } else {
                data = await apiClient.createRecord('doctor', record) as DoctorRecord;
            }

            // API returns a record with an empty 'documents' array
            data.documents = record.documents;

            for (const delFile of delFiles) {
                if (delFile.id) {
                    try {
                        await apiClient.deleteDocument(delFile.id);
                        data.documents = data.documents.filter((i) => i.id !== delFile.id);
                    } catch (e) {
                        console.log('failed to delete file', e);
                    }
                }
            }

            for (const newFile of newFiles) {
                if (data.id) {
                    try {
                        const result: RecordAttachment = await apiClient.uploadDocument(data.id, newFile);
                        data.documents.push(result);
                    } catch (e) {
                        console.log('failed to upload file', e);
                    }
                }
            }

            if (record.id) {
                setRecords(records.map((i) => i.id === record.id ? data : i));
            } else {
                setRecords(sortBy([data, ...records], 'record_at', true));
            }

            setOpenedRecord(undefined);

            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t(`${record.id ? 'messages.success.update' : 'messages.success.create'}`)}));

            return true;
        } catch (e) {
            console.log(e);
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));

            return false;
        }
    }

    async function deleteSelectedRecords(): Promise<void> {
        try {
            const ids: number[] = selectedRecords.reduce((a: number[], i) => i.id ? [...a, i.id] : a, []);
            await apiClient.deleteRecords('doctor', ids);

            setRecords(records.filter((i) => !i.id || !ids.includes(i.id)));

            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t('messages.success.delete')}));
        } catch (e) {
            console.log(e);
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));
        }
    }

    async function init() {
        await fetchDoctors();
        await fetchRecords();
    }

    useEffect(() => {
        init();
    }, []);

    const columns = useMemo(() => [
        {
            Header: t('date'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            accessor: 'record_at',
            disableSortBy: true,
            Cell: ({value}: FixType) => dayjs(value).format('ll'),
        },
        {
            Header: t('doctor'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            accessor: 'category_id',
            disableSortBy: true,
            Cell: ({value}: FixType) => (doctors.find((i) => i.id === value) || {}).name,
        },
        {
            Header: t('brief_diagnosis'),
            accessor: 'short_text',
            disableSortBy: true,
        },
    ], [doctors]);

    return (
        <>
            <Page title={t('entering_values_medical_card')}>
                <Panel
                    title={t('visit_a_doctor')}
                    loading={loading}
                    actions={<>
                        <PanelAction type={'add'} onClick={() => setOpenedRecord({} as FixType)} />
                        <PanelAction type={'delete'} disabled={selectedRecords.length === 0} onClick={() => confirm(t('confirm_delete')) && deleteSelectedRecords()} />
                    </>}
                >
                    <SortTable
                        columns={columns}
                        data={records as FixType}
                        onRowClick={setOpenedRecord}
                        onRowSelect={setSelectedRecords}
                        selectable={true}
                    />
                </Panel>
            </Page>

            <Modal
                isOpen={!!openedRecord}
                onClose={() => setOpenedRecord(undefined)}
                title={t('visit_a_doctor')}
            >
                <CardDoctorForm
                    record={openedRecord}
                    onCancel={() => setOpenedRecord(undefined)}
                    onSubmit={saveRecord}
                />
            </Modal>
        </>
    );
};

export default CardDoctor;
