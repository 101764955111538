import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

import {useAppDispatch, useAppSelector} from '../../../module/redux/hooks';
import {treeToList} from '../../../service/util';
import {showSnackbar} from '../../../component/Snackbar/slice';
import {fetchCategories, fetchRecords, saveRecord, deleteRecords, resetCard, setType} from '../slice';
import SortTable from '../../../component/SortTable';
import Modal from '../../../component/Modal';
import {SNACKBAR_TYPE_DANGER, SNACKBAR_TYPE_SUCCESS} from '../../../component/Snackbar';
import Page from '../../../component/Page';
import Panel from '../../../component/Panel';
import PanelAction from '../../../component/PanelAction';
import CardAnalysisForm from '../../../component/form/CardAnalysis';

const CardAnalysis = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const {loading, categories, records} = useAppSelector(state => state.cardPage);

    const [openedRecord, setOpenedRecord] = useState(undefined);
    const [selectedRecords, setSelectedRecords] = useState<AnalysisRecord[]>([]);

    async function onSaveRecord(record: AnalysisRecord, delFiles: RecordAttachment[], newFiles: RecordAttachment[]): Promise<boolean> {
        try {
            await dispatch(saveRecord({record, delFiles, newFiles}));

            setOpenedRecord(undefined);

            dispatch(showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t(`${record.id ? 'messages.success.update' : 'messages.success.create'}`)}));

            return true;
        } catch (e) {
            console.log(e);
            dispatch(showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));

            return false;
        }
    }

    async function deleteSelectedRecords(): Promise<void> {
        try {
            const ids: number[] = selectedRecords.filter(r => r.id).map(r => r.id!);
            await dispatch(deleteRecords(ids));

            dispatch(showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t('messages.success.delete')}));
        } catch (e) {
            console.log(e);
            dispatch(showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));
        }
    }

    useEffect(() => {
        (async () => {
            dispatch(setType('analysis'));
            await dispatch(fetchCategories());
            await dispatch(fetchRecords());
        })();

        return () => {
            dispatch(resetCard());
        };
    }, []);

    const features: {[key: number]: CategoryFeature} = useMemo(() => {
        const feats: {[key: number]: CategoryFeature} = {};

        categories.flatMap(c => treeToList(c)).filter(c => c.features.length).forEach(c => {
            c.features.forEach(f => {
                feats[f.id] = f;
            });
        });

        return feats;
    }, [categories]);

    const columns = useMemo(() => [
        {
            Header: t('date'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%'
            },
            accessor: 'datetime_at',
            disableSortBy: true,
            Cell: ({value}: FixType) =>
                dayjs(value).format('ll')
        }, {
            Header: t('time'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%'
            },
            Cell: ({row: {original: {datetime_at}}}: FixType) =>
                dayjs(datetime_at).format('HH:mm')
        }, {
            Header: t('type'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%'
            },
            accessor: 'category_id',
            disableSortBy: true,
            Cell: ({value}: FixType) =>
                t(categories.find(c => c.id === value)?.name ?? '')
        }, {
            Header: t('indicators'),
            accessor: 'values',
            disableSortBy: true,
            Cell: ({value}: FixType) =>
                value.map((i: RecordValue) =>
                    `${t((features[i.feature_id] || {}).name)}: ${i.value} (${t(i.measure ?? features[i.feature_id]?.measure ?? 'mg_dl')})`
                ).join(', ')
        }
    ], [categories, features, i18n.language]);

    return (
        <>
            <Page title={t('entering_values_medical_card')}>
                <Panel
                    title={t('analyses')}
                    loading={loading}
                    actions={<>
                        <PanelAction type={'add'} onClick={() => setOpenedRecord({} as FixType)} />
                        <PanelAction type={'delete'} disabled={selectedRecords.length === 0}
                            onClick={() => confirm(t('confirm_delete')) && deleteSelectedRecords()}
                        />
                    </>}
                >
                    <SortTable
                        columns={columns}
                        data={records as FixType}
                        onRowClick={setOpenedRecord}
                        onRowSelect={setSelectedRecords}
                        selectable={true}
                    />
                </Panel>
            </Page>

            <Modal
                isOpen={!!openedRecord}
                onClose={() => setOpenedRecord(undefined)}
                title={t('analyses')}
                size={'large'}
            >
                <CardAnalysisForm
                    record={openedRecord}
                    onCancel={() => setOpenedRecord(undefined)}
                    onSubmit={onSaveRecord}
                />
            </Modal>
        </>
    );
};

export default CardAnalysis;
