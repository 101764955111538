import dayjs from 'dayjs';
import i18n from '../i18nConfig';
import { ScatterData, BoxPlotData as BoxData, Layout, Shape } from 'plotly.js';

import { CategoryName } from '../enums';
import {
    commonPlotMargin, commonPlotAxis,
    commonBoxMargin, commonBoxAxis, commonBoxData
} from './plotChunks';
import { KeyFeelingIndicator, MIN_FEELING_INDICATOR } from '../component/InputRange';


export const genScatterDataset = (
    category: KmrCategory, diary: WidgetDiaryData[], from: string, to: string, featureNames?: string[]
): [Partial<ScatterData>[], Partial<Layout>] => {
    const data: Partial<ScatterData>[] = [];

    category.features.filter(feature => !featureNames || featureNames.includes(feature.name)).forEach(feature => {
        const x: FixType[] = [];
        const y: FixType[] = [];

        diary.forEach(d =>
            d.values.filter(v => v.feature_id === feature.id)
                .forEach(({value}) => {
                    x.push(d.datetime_at);
                    y.push(isNaN(Number(value)) ? value : Number(value));
                })
        );

        data.push({
            x,
            y,
            text: y,
            textposition: 'top center',
            mode: 'text+lines+markers',
            line: {
                // spline for Pressure and Pulse categories
                shape: [CategoryName.Pressure, CategoryName.Pulse].includes(category.name as CategoryName) ? 'spline' : 'linear',
                width: 1
            }
        });
    });

    const layout: Partial<Layout> = {
        showlegend: false,
        hovermode: 'closest',
        height: 150,
        margin: commonPlotMargin(),
        xaxis: {
            ...commonPlotAxis(),
            tickformat: '%H:%M\n%b. %d, %Y',
            /** Add one day for visibility of last markers **/
            range: [from, dayjs(to).add(1, 'day').format('YYYY-MM-DD')],
            autorange: false,
            fixedrange: true
        },
        yaxis: {
            ...commonPlotAxis(i18n.t(category.name)),
            showgrid: false,
            range: getYaxisRange(data),
            autorange: false,
            fixedrange: true
        }
    };

    return [data, layout];
};

export const genBoxDataset = (
    category: KmrCategory, diary: WidgetDiaryData[], from: string, to: string, featureNames?: string[], valueConverter?: FixType
): [Partial<BoxData>[], Partial<Layout>] => {
    const data: Partial<BoxData>[] = [];

    const daysToGroup = Math.floor(dayjs(to).diff(from, 'months', true)) + 1;
    const xPeriod = daysToGroup * 24 * 3600 * 1000;

    category.features.filter(feature => !featureNames || featureNames.includes(feature.name)).forEach(feature => {
        const boxes: Partial<BoxData>[] = [];
        diary.forEach(({datetime_at, values}) => {
            const value = (values.find(v => v.feature_id === feature.id) || {}).value;

            if (value) {
                const i = Math.floor(dayjs(datetime_at).diff(from) / xPeriod);
                if (!boxes[i]) {
                    boxes[i] = {
                        ...commonBoxData(),
                        width: xPeriod / 1.5,
                        /** whiskerwidth is missing in type BoxPlotData */
                        // eslint-disable-next-line
                        // @ts-ignore
                        whiskerwidth: .75
                    };
                }
                (boxes[i].y as Array<FixType>).push(isNaN(+value) ? (valueConverter ? valueConverter(value, datetime_at) : value) : +value);
            }
        });

        data.push(...convertBoxesToTraces(boxes, from, to, xPeriod));
    });

    const layout: Partial<Layout> = {
        showlegend: false,
        hovermode: 'x',
        height: 150,
        margin: commonBoxMargin(),
        xaxis: {
            ...commonBoxAxis(),
            range: getXaxisRange(from, to),
            type: 'date',
            ticks: '',
            tickfont: {
                color: getComputedStyle(document.body).getPropertyValue('--primary-clr'),
                family: 'Roboto, sans-serif',
                size: 11,
            }
        },
        yaxis: {
            ...commonBoxAxis(i18n.t(category.name)),
            range: getYaxisRange(data, 1.1)
        }
    };

    return [data, layout];
};

export const genReferenceShapes = ([range1, range2]: [number, number], [ref1, ref2]: [number, number]): Partial<Shape>[] => {
    const rect: Partial<Shape> = {
        type: 'rect',
        xref: 'paper',
        x0: 0, x1: 1,
        layer: 'below',
        line: { width: 0 },
        fillcolor: 'rgba(245, 216, 57, .06)'
    };

    return [{ // background to top
        ...rect,
        y0: ref2, y1: range2
    }, { // background center
        ...rect,
        y0: ref1, y1: ref2,
        fillcolor: 'rgba(27, 194, 0, .12)'
    }, { // background to bottom
        ...rect,
        y0: ref1, y1: range1
    }];
};

export const getFeelingGlyphByValue = (value: number): string => {
    const feeling = getFeelingByValue(value);

    return feeling.charAt(0);
};

export const getFeelingColorByValue = (value: number, opacity?: number): string => {
    const feeling = getFeelingByValue(value);

    switch (feeling) {
        case 'Terrible': return `rgba(255, 29, 8, ${opacity ?? 1})`;
        case 'Bad': return `rgba(252, 109, 31, ${opacity ?? 1})`;
        case 'Normal': return `rgba(248, 210, 42, ${opacity ?? 1})`;
        case 'Good': return `rgba(108, 184, 79, ${opacity ?? 1})`;
        case 'Excellent': return `rgba(7, 165, 94, ${opacity ?? 1})`;
        default: return `rgba(248, 210, 42, ${opacity ?? 1})`;
    }
};


const convertBoxesToTraces = (
    boxes: Partial<BoxData>[], from: string, to: string, xPeriod: number
): Partial<BoxData>[] => {
    const arr = boxes.map((b, i) => {
        const start = dayjs.utc(from, 'YYYY-MM-DD').add(i * xPeriod);
        const end   = dayjs(start).add(xPeriod).subtract(1, 'day');

        b.name = start.format('DD/MM/YYYY');
        if (!start.isSame(end, 'day')) {
            b.name += end.format(' - DD/MM/YYYY');
        }
        b.x = Array(b.y?.length).fill(dayjs(start).add(xPeriod / 2).format());
        return b;
    }).filter(b => b);

    return [...arr, ...arr.map(b => {
        const median = getMedianOfArray(b.y as number[]);
        return {
            x: [(b.x ?? [0])[0]],
            y: [median],
            mode: 'markers',
            marker: {
                size: 10,
                opacity: 0
            },
            hoverinfo: 'text',
            hovertext: `<b>${b.name}</b><br>` + (Object.hasOwn(b, 'hovertext') ? `<br>${b.hovertext}` : `${i18n.t('median')} ${median}`),
            hoverlabel: {
                align: 'left',
                font: {
                    color: '#FFF',
                    family: 'Roboto, sans-serif',
                    size: 12

                },
                bgcolor: '#4C5252'
            }
        } as BoxData;
    })];
};

const getFeelingByValue = (value: number): string => {
    return (Object.keys(MIN_FEELING_INDICATOR) as KeyFeelingIndicator[]).reduce((previous, current) =>
        value >= MIN_FEELING_INDICATOR[previous] && value < MIN_FEELING_INDICATOR[current] ? previous : current
    );
};

const getXaxisRange = (from: string, to: string): [string, string] => {
    const daysToGroup = Math.floor(dayjs(to).diff(from, 'months', true)) + 1;
    const div = dayjs(to).diff(from, 'days') % daysToGroup;

    return [from, dayjs(to).add(div > 0 ? daysToGroup - div : 1, 'day').format('YYYY-MM-DD')];
};

export const getYaxisRange = (data: Partial<ScatterData|BoxData>[], k?: number): [number, number] => {
    const arr: number[] = data.flatMap(item => (item.y as string[]).filter(item => !isNaN(+item)).map(item => +item));
    if (arr.length) {
        const [min, max] = [Math.min(...arr), Math.max(...arr)];
        const [signMin, signMax] = [Math.sign(min), Math.sign(max)];
        const [absMin, absMax] = [Math.abs(min), Math.abs(max)];

        if (typeof k !== 'number') k = 1.5;

        return [(signMin > 0 ? absMin / k : absMin * k) * signMin, (signMax > 0 ? absMax * k : absMax / k) * signMax];
    }
    return [0, 1];
};

const getMedianOfArray = (arr: number[]): number => {
    const mid = Math.floor(arr.length / 2);
    const nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};
