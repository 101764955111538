import { useMemo } from 'react';
import { Data as PlotData, ScatterData, Layout, Shape } from 'plotly.js';

import DiaryPlot from './plot';
import { useAppSelector } from '../../../../module/redux/hooks';
import {genScatterDataset, genBoxDataset, genReferenceShapes, getYaxisRange} from '../../../../service/plotUtils';
import { FeatureName } from '../../../../enums';


const Y_RANGE: [number, number] = [0, 300];
const REFERENCE: [number, number] = [60, 80];

const PulsePlot = ({category}: {category: KmrCategory}) => {
    const state = useAppSelector((state) => state.diaryWidget);

    const [data, layout] = useMemo(() => {
        let data: Partial<PlotData>[];
        let layout: Partial<Layout>;

        if (state.mode === 'box') {
            [data, layout] = genBoxDataset(category, state.diary, state.from, state.to, [FeatureName.Pulse]);
            modifyBoxDataset(data);
        } else {
            [data, layout] = genScatterDataset(category, state.diary, state.from, state.to);
            modifyScatterDataset(data, layout);
        }

        layout.shapes = [
            ...genReferenceShapes(Y_RANGE, REFERENCE),
            ...(layout.shapes ?? [])
        ];

        return [data, layout];
    }, [state.diary]);

    return <DiaryPlot data={data} layout={layout} />;
};

export default PulsePlot;

const modifyScatterDataset = (data: Partial<PlotData>[], layout: Partial<Layout>) => {
    /** Arrhythmia lines */
    if (data.length === 2) {
        const tmp = data.pop() as ScatterData;

        tmp.y.forEach((v: FixType, i: number) => {
            if (`${v}`.toLowerCase() === 'true') {
                if (!layout.shapes) layout.shapes = [];
                layout.shapes.push({
                    type: 'line',
                    x0: tmp.x[i],
                    x1: tmp.x[i],
                    y0: 0,
                    y1: (data[0] as ScatterData).y[i],
                    line: {
                        color: '#B73333',
                        width: 2,
                        dash: undefined
                    }
                } as Partial<Shape>);
            }
        });
    }

    const range = getYaxisRange(data as Partial<ScatterData>[], 1.25);
    layout.yaxis = {
        ...layout.yaxis,
        range,
        ...(range[1] - range[0] < 150 ? {
            tick0: 80,
            dtick: 20,
            minor: {
                tick0: 90,
                dtick: 20,
                ticklen: 5
            }
        } : {})
    };
};

const modifyBoxDataset = (data: Partial<PlotData>[]) => {
    data.filter(d => d.type === 'box').forEach((d: FixType) => {
        d.line.color = 'rgb(183, 51, 51)';
        d.fillcolor = 'rgba(183, 51, 51, .4)';
    });
};