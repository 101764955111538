import {useEffect} from 'react';
import Panel from '../../Panel';
import {useTranslation} from 'react-i18next';
import AnalysisGrid from '../../AnalysisGrid';
import ToggleGroup from '../../ToggleGroup';
import DateRangePicker from '../../DateRangePicker';
import {useAppDispatch, useAppSelector} from '../../../module/redux/hooks';
import {actions, init} from './slice';

const AnalysisWidget = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.analysisWidget);

    const onChangeRange = (from: string, to: string) => {
        dispatch(actions.setDates({from, to}));
    };

    useEffect(() => {
        dispatch(init(state.from, state.to));
    }, [state.from, state.to]);

    useEffect(() => {
        dispatch(actions.setFilledCategories());
    }, [state.data, state.categories]);

    return <Panel
        title={t('analysis')}
        loading={state.loading}
        actionsAsList={true}
        actions={<DateRangePicker type={'month'} range={[state.from, state.to]} onChangeRange={onChangeRange}/>}
    >
        <ToggleGroup
            onChange={(id) => dispatch(actions.toggleCategory(id))}
            options={state.categories.map((i) => ({
                name: t(i.name),
                value: i.id,
                active: state.activeCategories[i.id],
                filled: state.filledCategories[i.id],
            }))}
        />

        {state.categories.filter((c) => state.activeCategories[c.id]).map((c) => <AnalysisGrid
            key={c.id}
            category={c}
            data={state.data.filter((d) => d.category_id === c.id)}
            from={state.from}
            to={state.to}
        />)}
    </Panel>;
};

export default AnalysisWidget;
