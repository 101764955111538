import {useEffect, useState} from 'react';
import Input from '../Input';
import Button from '../Button';
import {useTranslation} from 'react-i18next';
import {getLastWeek, getLastMonth, getLast6Months} from '../../service/util';
import dayjs from 'dayjs';

type ThisComponentProps = {
    type: string,
    range: string[],
    onChangeRange(from: string, to: string): void,
    hasWeekMonthOptions?: boolean
}

const DateRangePicker = ({type, range, onChangeRange, hasWeekMonthOptions} : ThisComponentProps) => {
    const { t } = useTranslation();
    const [from, to] = range;
    const [period, setPeriod] = useState<WidgetPeriod>('custom');

    const onWeekClick = () => {
        const [newFrom, newTo] = getLastWeek();

        onChangeRange(newFrom, newTo);
    };

    const onMonthClick = () => {
        const [newFrom, newTo] = getLastMonth();

        onChangeRange(newFrom, newTo);
    };

    const on6MonthsClick = () => {
        const [newFrom, newTo] = getLast6Months();

        onChangeRange(newFrom, newTo);
    };

    useEffect(() => {
        let newPeriod: WidgetPeriod = 'custom';


        if (dayjs().isSame(to, 'day')) {
            const days = dayjs(to).diff(from, 'day');
            const months = dayjs(to).diff(from, 'month', true);

            if (days === 7) {
                newPeriod = 'week';
            } else if (months === 1) {
                newPeriod = 'month';
            } else if (months === 6) {
                newPeriod = 'six_months';
            }
        }

        setPeriod(newPeriod);
    }, [from, to]);

    return (
        <>
            <div>
                <Input>
                    <input
                        type={type}
                        required={true}
                        placeholder={t('yyyy_mm')}
                        value={from}
                        onChange={(e) => onChangeRange(e.target.value, to)}
                    />
                </Input>

                <span>-</span>

                <Input>
                    <input
                        type={type}
                        required={true}
                        placeholder={t('yyyy_mm')}
                        value={to}
                        onChange={(e) => onChangeRange(from, e.target.value)}
                    />
                </Input>
            </div>
            {hasWeekMonthOptions && type !== 'month' && (
                <>
                    <Button
                        type={'button'}
                        isSelected={period === 'week'}
                        onClick={onWeekClick}
                    >{t('week')}</Button>

                    <Button
                        type={'button'}
                        isSelected={period === 'month'}
                        onClick={onMonthClick}
                    >{t('month')}</Button>

                    <Button
                        type={'button'}
                        isSelected={period === 'six_months'}
                        onClick={on6MonthsClick}
                    >{t('six_months')}</Button>
                </>)
            }
        </>
    );
};

export default DateRangePicker;