import dayjs from 'dayjs';

export const clone = <T>(a: T): T => JSON.parse(JSON.stringify(a));

export const dateStringToMonth = (s: string): string => s.split('-', 2).join('-');

export const dateStringToMonthFirstDay = (s: string): string => `${s}-01`;

export const genDates = (from: string, to: string): string[] => {
    const dates: string[] = [];
    const fromDate = new Date(from);
    const toDate = new Date(to);

    while (fromDate <= toDate) {
        dates.push(
            [fromDate.getFullYear(), fromDate.getMonth() + 1, fromDate.getDate()]
                .map((i) => `${i}`.padStart(2, '0'))
                .join('-')
        );
        fromDate.setDate(fromDate.getDate() + 1);
    }

    return dates;
};

export const genMonths = (from: string, to: string): string[] => {
    const dates: string[] = [];
    const fromDate = new Date(from);
    const toDate = new Date(to);

    while (fromDate <= toDate) {
        dates.push(
            [fromDate.getFullYear(), fromDate.getMonth() + 1]
                .map((i) => `${i}`.padStart(2, '0'))
                .join('-')
        );
        fromDate.setMonth(fromDate.getMonth() + 1);
    }

    return dates;
};

export const getLastWeek = () => {
    return [dayjs().subtract(7, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')];
};

export const getLastMonth = () => {
    return [dayjs().subtract(1, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')];
};

export const getLast6Months = () => {
    return [dayjs().subtract(6, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')];
};

export const treeToList = <T extends { children: T[]}>(tree: T, list: T[] = []): T[] => {
    list.push(tree);

    for (const item of tree.children) {
        list.push(...treeToList(item));
    }

    return list;
};

export const sortBy = <T extends Record<string, FixType>>(target: Array<T>, by: string, desc = false): Array<T> => {
    const tmp = target.sort((a, b) => a[by] > b[by] ? 1 : a[by] < b[by] ? -1 : 0);
    return desc ? tmp.reverse() : tmp;
};
