import axios, {AxiosInstance} from 'axios';
import * as idbKeyval from 'idb-keyval';
import config from '../config';

// logs error message and throws error if we are not in development mode
// function handleException(err: FixType, customMsg: string) {
//     const message = err.response && err.response.data ? err.response.data.message : err.message;
//     console.log(`Archiver client - ${customMsg}: ${message}`);
//     if (!DEV_MODE) {
//         throw err;
//     }
// }

class ApiClient {
    private http: AxiosInstance;
    private token: Undef<string>;
    private readonly baseURL: Undef<string>;

    private cache: FixType = {};

    constructor(host: string, port?: string | number, path?: string) {
        this.baseURL = `${host}${port ? `:${port}` : ''}${path ? `${path}` : ''}`;

        this.http = axios.create({
            baseURL: this.baseURL,
            // timeout: 1000,
            // headers: {'X-Custom-Header': 'foobar'}
        });

        const auth = this.getCachedAuth();

        if (auth) {
            this.http.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
        }

        // intercept all outgoing requests
        // this.http.interceptors.request.use(VehicleApi.beforeRequest);

        // intercept all incoming responses
        this.http.interceptors.response.use(ApiClient.onRequestSuccess, this.onRequestFailure.bind(this));
    }

    public getCachedAuth(): Null<KmrAuthData> {
        try {
            return JSON.parse(localStorage.getItem('auth') || '') || null;
        } catch (e) {
            return null;
        }
    }

    // private static async beforeRequest(request) {
    //     const auth0 = await getAuth0Client();
    //     const token = await auth0.getTokenSilently();
    //
    //     // add an auth header
    //     request.headers.Authorization = `Bearer ${token}`;
    //
    //     return request;
    // }

    private static onRequestSuccess(response: FixType) {
        // unwrap returned data
        return response.data;
    }

    private async onRequestFailure(err: FixType) {
        console.log('request failed', err);
        // check for the `401` status and force-logout, otherwise proceed with regular workflow
        if (err.response.status === 401) {
            await this.logout();
        } else {
            console.log('fetch failed', err);
        }

        throw err;
    }

    private async getKmrAuthData(endpoint: string, postData: FixType): Promise<KmrAuthData> {
        const data: KmrAuthData = await this.http.post(endpoint, postData);

        // do not cache user part!
        localStorage.setItem('auth', JSON.stringify({...data, user: null}));
        await idbKeyval.set('token', data.token);

        this.http.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;

        return data;
    }

    async login(login: string, password: string): Promise<KmrAuthData> {
        return await this.getKmrAuthData('auth/login', { login, password });
    }

    async logout(): Promise<void> {
        localStorage.removeItem('auth');
        await idbKeyval.del('token');

        await this.http.post('auth/logout');

        window.location.reload();
    }

    async register(login: string, email: string, password: string): Promise<KmrAuthData> {
        return await this.getKmrAuthData('auth/registration', { login, email, password, role: 'user' });
    }

    async resetPassword(email: string) {
        return await this.http.post('auth/reset-password', {email});
    }

    async changePassword(old_password: string, new_password: string): Promise<{complete: boolean}> {
        return await this.http.post('auth/password/change', {
            old_password,
            new_password
        });
    }

    async getUser(): Promise<KmrUser> {
        return this.http.get('user/get');
    }

    async updateUser(user: KmrUser): Promise<KmrUser> {
        return this.http.put('user/update', user);
    }

    async createRecord(type: string, record: KmrRecord): Promise<KmrRecord> {
        return this.http.post(`record/${type}/create`, record);
    }

    async updateRecord(type: string, record: KmrRecord): Promise<KmrRecord> {
        return this.http.put(`record/${type}/update`, record);
    }

    async deleteRecord(type: string, id: number): Promise<void> {
        return this.http.delete(`record/${type}/delete`, {
            params: {
                id,
            }
        });
    }

    async deleteRecords(type: string, ids: number[]): Promise<void> {
        return this.http.delete(`record/${type}/delete_batch`, {
            data: { ids }
        });
    }

    async getRecords(type: string, start_date?: string, end_date?: string): Promise<KmrRecord[]> {
        return this.http.get(`record/${type}/items`, {
            params: {start_date, end_date}
        });
    }

    async getProviders(): Promise<FixType[]> {
        return this.fetchStaticData('provider/items');
    }

    async getDoctors(): Promise<KmrCategory[]> {
        return this.fetchCategory('doctor');
    }

    async getTests(): Promise<KmrCategory[]> {
        return this.fetchCategory('analysis');
    }

    async getDiaries(): Promise<KmrCategory[]> {
        return this.fetchCategory('diary');
    }

    async getAnamnesis(): Promise<AnamnesisResponse> {
        return this.http.get('anamnesis/items');
    }

    async updateAnamnesis(record: Anamnesis): Promise<AnamnesisResponse> {
        return this.http.patch('anamnesis/update', record);
    }

    async createAnamnesis(record: Anamnesis): Promise<AnamnesisResponse> {
        return this.http.post('anamnesis/create', record);
    }

    async deleteAnamnesis(ids: number[]): Promise<void> {
        return this.http.delete('anamnesis/delete_batch', {
            data: { ids }
        });
    }

    async fetchCategory(word: string, params?: FixType): Promise<FixType[]> {
        return this.fetchStaticData(`category/all?category=${word}`, params);
    }

    async fetchCategoryTags(): Promise<AnalysisTags> {
        return this.fetchStaticData('category/tags');
    }

    async fetchStaticData(url: string, params?: FixType): Promise<FixType> {
        const key = [url, params ? JSON.stringify(params) : undefined].filter(Boolean).join('?');

        return this.cache[key] || (this.cache[key] = this.http.get(url, { params }));
    }

    async uploadDocument(record_id: number, file: FixType): Promise<RecordAttachment> {
        const formData = new FormData();
        formData.append('upload_file', file);

        return this.http.post('document/upload', formData,{
            params: {
                record_id,
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    async deleteDocument(id: number): Promise<void> {
        return this.http.delete('document/delete', {
            params: { id }
        });
    }

    async deleteDocuments(ids: number[]): Promise<void> {
        return this.http.delete('document/delete_batch', {
            data: { ids }
        });
    }

    async downloadDocument(fid: string, preview = false): Promise<FixType> {
        return this.http.get(`document/download/${fid}`, {
            timeout: 35000,
            responseType: 'blob',
            params: { preview }
        });
    }

    async getDoctorsWidgetData(start_date: string, end_date: string): Promise<WidgetDoctor[]> {
        return this.http.get('widget/doctors', {
            params: {
                start_date,
                end_date,
            }
        });
    }

    async getTrendsData(start_date: string, end_date: string): Promise<HeartTrendData> {
        return this.http.get('widget/trend', {
            params: {
                start_date,
                end_date,
            }
        });
    }

    async getDrugsWidgetData(start_date: string, end_date: string): Promise<WidgetDrugsData[]> {
        return this.http.get('widget/pills', {
            params: {
                start_date,
                end_date,
            }
        });
    }

    async getDiaryWidgetData(start_date: string, end_date: string): Promise<WidgetDiaryData[]> {
        return await this.getRecords('diary', start_date, end_date) as WidgetDiaryData[];
    }

    async getAnalysisWidgetData(start_date: string, end_date: string): Promise<WidgetAnalysisData[]> {
        return await this.getRecords('analysis', start_date, end_date) as WidgetAnalysisData[];
    }

    async createDrug(drug: KmrDrug): Promise<KmrDrug> {
        return this.http.post('drug/create', drug);
    }

    async updateDrug(drug: KmrDrug): Promise<KmrDrug> {
        return this.http.put('drug/update', drug);
    }

    async deleteDrug(id: number): Promise<void> {
        return this.http.delete('drug/delete', {
            params: {
                id,
            }
        });
    }

    async deleteDrugs(ids: number[]): Promise<void> {
        return this.http.delete('drug/delete_batch', {
            data: { ids }
        });
    }

    async getDrugs(): Promise<KmrDrug[]> {
        return  this.http.get('drug/items');
    }

    async getReport(uri: string): Promise<KmrReportData> {
        return this.http.get('measure/share/report', {
            params: {
                uri
            }
        });
    }

    async getMoods(start_date: string, end_date: string): Promise<FixType[]> {
        return this.http.get('measure/mood/values', {
            params: {
                start_date,
                end_date,
            }
        });
    }

    async getDrugsAutocomplete(q: string): Promise<DrugAutocomplete[]> {
        return this.http.get('product/autocomplete', {
            params: { q }
        });
    }

    async createShareRequest(payload: KmrShareRequest, access: boolean): Promise<KmrShareRecord> {
        return await this.http.post('user/share/create', payload, {params: {access}});
    }

    async acceptShareRequest(payload: KmrShareResponse): Promise<KmrShareRecord> {
        return await this.http.put('user/share/accept', payload);
    }

    async rejectShareRequest(uri: string): Promise<KmrShareRecord> {
        return await this.http.put('user/share/reject', {}, {params: {uri}});
    }

    async revokeShareRequest(uri: string): Promise<KmrShareRecord> {
        return await this.http.put('user/share/revoke', {}, {params: {uri}});
    }

    async getAccessibleCards(): Promise<KmrShareRecord[]> {
        return await this.http.get('user/share/accessible');
    }

    async getRequestedCards(): Promise<KmrShareRecord[]> {
        return await this.http.get('user/share/requests');
    }

    async getSharedCards(): Promise<KmrShareRecord[]> {
        return await this.http.get('user/share/shared');
    }

    async getSharedData(uri: string, start_date: string, end_date: string): Promise<WidgetHeartData[]> {
        return await this.http.get('user/share/data/heart', {
            params: {uri, start_date, end_date}
        });
    }

    async getDiseasesAutocomplete(q: string, lang?: string): Promise<DiseaseAutocomplete[]> {
        return this.http.get('diseases/autocomplete', {
            params: { q, lang }
        }).then(resp => resp.data);
    }
}

const apiClient = new ApiClient(config.api.host, config.api.port, config.api.path);

export default apiClient;
