import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from './module/auth';
import './i18nConfig'; // init `i18next` so that it could be used later in the app
import apiClient from './service/apiClient';
import {Provider as ReduxProvider} from 'react-redux';
import store from './module/redux/store';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(localizedFormat);
dayjs.extend(utc);

const auth: Null<KmrAuthData> = apiClient.getCachedAuth();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <StrictMode>
        <ReduxProvider store={store}>
            <BrowserRouter>
                <AuthProvider initAuth={!auth ? null : {
                    accessToken: auth.token,
                    roles: [auth.role],
                    user: null,
                }}>
                    <App />
                </AuthProvider>
            </BrowserRouter>
        </ReduxProvider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
