import styles from './style.module.css';

import Page from '../../../component/Page';
import Panel from '../../../component/Panel';
import Patient from '../../../component/Doctor/Patient';
import {useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../module/redux/hooks';
import {actions, fetchSharedCards, fetchSharedData} from './slice';
import {useTranslation} from 'react-i18next';
import DateRangePicker from '../../../component/DateRangePicker';
import {Data as Traces, Shape} from 'plotly.js';
import Plot from 'react-plotly.js';
import {commonPlotAxis, commonPlotMargin} from '../../../service/plotChunks';

const Contacts = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.contactsPage);
    const [selectedCard, setSelectedCard] = useState<KmrShareRecord | undefined>();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const tabs = [t('heart_condition'), t('diabetes'), t('patient_diagnoses'), t('chat')];

    const onChangeRange = (from: string, to: string) => {
        dispatch(actions.setDates({from, to}));
    };

    useEffect(() => {
        dispatch(fetchSharedCards());
    }, []);

    useEffect(() => {
        if (selectedCard) {
            dispatch(fetchSharedData(selectedCard.uri, state.from, state.to));
        }
    }, [selectedCard, state.from, state.to]);

    const {traces, shapes}: {traces: Traces[], shapes: Partial<Shape>[]} = useMemo(() => {
        const traces: Traces[] = [];
        const shapes: Partial<Shape>[] = [];

        state.sharedData.forEach((i) => i.arrhythmia && shapes.push({
            type: 'line',
            x0: i.date,
            x1: i.date,
            y0: 0,
            y1: .75,
            xref: 'x',
            yref: 'y',
            line:{
                color: '#B73333',
                width: 2,
            }
        }));

        traces.push({
            name: t('arrhythmia'),
            y: [0, 1],
            xaxis: 'x',
            yaxis: 'y',
            marker: {
                color: 'rgb(87, 204, 169)'
            }
        });

        traces.push({
            name: t('pulse'),
            x: state.sharedData.filter(data => data.pulse).map(data => data.date),
            y: state.sharedData.filter(data => data.pulse).map(data => data.pulse ?? null),
            text: state.sharedData.filter(data => data.pulse).map(data => `${data.pulse || ''}`),
            mode: 'text+lines+markers',
            xaxis: 'x',
            yaxis: 'y2',
            textposition: 'top center',
            marker: {
                color: 'rgb(87, 204, 169)'
            }
        });

        traces.push({
            name: t('systolic'),
            x: state.sharedData.filter(data => data.syst).map(data => data.date),
            y: state.sharedData.filter(data => data.syst).map(data => data.syst ?? null),
            text: state.sharedData.filter(data => data.syst).map(data => `${data.syst || ''}`),
            xaxis: 'x',
            yaxis: 'y3',
            mode: 'text+lines+markers',
            textposition: 'top center',
            marker: {
                color: 'rgb(180, 198, 26)'
            }
        });

        traces.push({
            name: t('diastolic'),
            x: state.sharedData.filter(data => data.dist).map(data => data.date),
            y: state.sharedData.filter(data => data.dist).map(data => data.dist ?? null),
            text: state.sharedData.filter(data => data.dist).map(data => `${data.dist || ''}`),
            xaxis: 'x',
            yaxis: 'y3',
            mode: 'text+lines+markers',
            textposition: 'top center',
            marker: {
                color: 'rgb(237, 124, 89)'
            }
        });

        return {traces, shapes};
    }, [state.sharedData]);

    return (
        <Page title={t('working_with_contacts')}>
            <Panel title={t('my_contacts')}>
                <div className={styles.patientsGrid}>
                    {state.sharedCards.map((card) => <Patient
                        key={card.uri}
                        card={card}
                        isSelected={selectedCard?.uri === card.uri}
                        onClick={setSelectedCard}
                    />)}
                </div>
            </Panel>

            {!!selectedCard && <Panel
                title={selectedCard.owner_alias}
                actionsAsList={true}
                actions={<DateRangePicker type={'date'} range={[state.from, state.to]} onChangeRange={onChangeRange} hasWeekMonthOptions={false} />}>

                <div className={styles.tabs}>
                    {tabs.map((tab, index) => <span
                        key={index}
                        className={index === selectedTab ? styles.sel : undefined}
                        onClick={() => setSelectedTab(index)}
                    >{tab}</span>)}
                </div>

                <div className={styles.charts}>
                    {traces.some((i: FixType) => i.x?.length > 0) ? (<Plot
                        data={traces}
                        layout={{
                            hovermode: 'x unified',
                            showlegend: false,
                            grid: {
                                rows: 5,
                                columns: 1,
                                subplots:['xy', 'xy2', 'xy3'],
                                roworder: 'top to bottom'
                            },
                            xaxis: {
                                ...commonPlotAxis(),
                                autorange: false,
                                range: [state.from, state.to]
                            },
                            yaxis: {
                                ...commonPlotAxis(t('arrhythmia')),
                                showgrid: false,
                                showticklabels: false,
                                ticklen: 0,
                                domain: [.600, .725]
                            },
                            yaxis2: {
                                ...commonPlotAxis(t('pulse')),
                                showgrid: false,
                                domain: [.375, .575]
                            },
                            yaxis3: {
                                ...commonPlotAxis(t('pressure')),
                                showgrid: false,
                                domain: [.150, .350]
                            },
                            margin: commonPlotMargin(),
                            shapes
                        }}
                        config={{displayModeBar: false}}
                        useResizeHandler={true}
                        className={styles.chart}
                    />) : <div>{t('no_data')}</div>}
                </div>
            </Panel>}
        </Page>
    );
};

export default Contacts;
