import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../module/redux/store';
import ApiClient from '../../service/apiClient';
import {sortBy, treeToList} from '../../service/util';

interface SliceType {
    loading: boolean;
    type?: 'analysis' | 'diary';
    categories: KmrCategory[];
    records: KmrRecord[];
}

const initialState: SliceType = {
    loading: false,
    categories: [],
    records: []
};

const getType = (api: {getState: FixType}) => {
    const state = api.getState() as RootState;
    return state.cardPage.type as string;
};

export const fetchCategories = createAsyncThunk('fetch/card/categories', async (_, api) => {
    return await ApiClient.fetchCategory(getType(api));
});

export const fetchRecords = createAsyncThunk('fetch/card/records', async (args: {from?: string, to?: string}|undefined,  api) => {
    return await ApiClient.getRecords(getType(api), args?.from, args?.to);
});

export const saveRecord = createAsyncThunk('save/card/records', async (args: {record: AnalysisRecord|KmrRecord, delFiles?: RecordAttachment[], newFiles?: RecordAttachment[]},  api) => {
    const type = getType(api);
    const {record, delFiles, newFiles} = args;

    const data = await ApiClient[record.id ? 'updateRecord' : 'createRecord'](type, record);

    if (type === 'analysis') {
        let docs: RecordAttachment[] = (data as AnalysisRecord).documents;

        const delFileIds = delFiles?.filter(f => f.id).map(f => f.id!) ?? [];
        if (delFileIds.length) {
            try {
                await ApiClient.deleteDocuments(delFileIds);
                docs = docs.filter(d => !d.id || !delFileIds.includes(d.id));
            } catch (e) {
                console.log('failed to delete file', e);
            }
        }

        for (const file of (newFiles ?? [])) {
            try {
                const doc: RecordAttachment = await ApiClient.uploadDocument(data.id!, file);
                docs.push(doc);
            } catch (e) {
                console.log('failed to upload file', e);
            }
        }

        // eslint-disable-next-line
        // @ts-ignore
        data.documents = docs;
    }

    return {id: record.id, data};
});

export const deleteRecords = createAsyncThunk('delete/card/records', async (ids: number[],  api) => {
    await ApiClient.deleteRecords(getType(api), ids);
    return ids;
});

const slice = createSlice({
    name: 'cardPage',
    initialState,
    reducers: {
        setType: (state, action: PayloadAction<SliceType['type']>) => {
            state.type = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        resetCard: (state) => {
            delete state.type;
            state.categories = [];
            state.records = [];
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload;
            })
            .addCase(fetchCategories.rejected, (state) => {
                state.loading = false;
            })
            .addCase(fetchRecords.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchRecords.fulfilled, (state, action) => {
                state.loading = false;
                state.records = sortBy(action.payload, 'datetime_at', true);
            })
            .addCase(fetchRecords.rejected, (state) => {
                state.loading = false;
            })
            .addCase(saveRecord.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveRecord.fulfilled, (state, action) => {
                state.loading = false;
                let records = state.records;
                if (action.payload.id) {
                    records = records.map(r => r.id === action.payload.id ? action.payload.data : r);
                } else {
                    records = [action.payload.data, ...state.records];
                }
                state.records = sortBy(records, 'datetime_at', true);
            })
            .addCase(saveRecord.rejected, (state) => {
                state.loading = false;
            })
            .addCase(deleteRecords.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteRecords.fulfilled, (state, action) => {
                state.loading = false;
                state.records = state.records.filter((i) => !i.id || !action.payload.includes(i.id));
            })
            .addCase(deleteRecords.rejected, (state) => {
                state.loading = false;
            });
    }
});

export const {setType, setLoading, resetCard} = slice.actions;

export default slice.reducer;