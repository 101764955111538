import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

import {useAppDispatch, useAppSelector} from '../../../module/redux/hooks';
import {treeToList} from '../../../service/util';
import {showSnackbar} from '../../../component/Snackbar/slice';
import {fetchCategories, fetchRecords, saveRecord, deleteRecords, resetCard, setType} from '../slice';
import SortTable from '../../../component/SortTable';
import Modal from '../../../component/Modal';
import {SNACKBAR_TYPE_DANGER, SNACKBAR_TYPE_SUCCESS} from '../../../component/Snackbar';
import {getFeelingColorByValue, getFeelingGlyphByValue} from '../../../service/plotUtils';
import Page from '../../../component/Page';
import Panel from '../../../component/Panel';
import PanelAction from '../../../component/PanelAction';
import CardDiaryForm from '../../../component/form/CardDiary';


const PRESSURE_FEATURE_SYST_ID = 3;
const PRESSURE_FEATURE_DIAST_ID = 4;
const FEELING_FEATURE_ID = 17;

const CardDiary = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const {loading, categories, records} = useAppSelector(state => state.cardPage);

    const [openedRecord, setOpenedRecord] = useState(undefined);
    const [selectedRecords, setSelectedRecords] = useState<DiaryRecord[]>([]);

    async function onSaveRecord(record: DiaryRecord): Promise<boolean> {
        try {
            await dispatch(saveRecord({record}));

            setOpenedRecord(undefined);

            dispatch(showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t(`${record.id ? 'messages.success.update' : 'messages.success.create'}`)}));

            return true;
        } catch (e) {
            console.log(e);
            dispatch(showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));

            return false;
        }
    }

    async function deleteSelectedRecords(): Promise<void> {
        try {
            const ids: number[] = selectedRecords.reduce((a: number[], i) => i.id ? [...a, i.id] : a, []);
            await dispatch(deleteRecords(ids));

            dispatch(showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t('messages.success.delete')}));
        } catch (e) {
            console.log(e);
            dispatch(showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));
        }
    }

    useEffect(() => {
        (async () => {
            dispatch(setType('diary'));
            await dispatch(fetchCategories());
            await dispatch(fetchRecords());
        })();

        return () => {
            dispatch(resetCard());
        };
    }, []);

    const features: {[key: number]: CategoryFeature} = useMemo(() => {
        const feats: {[key: number]: CategoryFeature} = {};

        categories.flatMap(c => treeToList(c)).filter(c => c.features.length).forEach(c => {
            c.features.forEach(f => {
                feats[f.id] = f;
            });
        });

        return feats;
    }, [categories]);

    const columns = useMemo(() => [
        {
            Header: t('date'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            accessor: 'datetime_at',
            disableSortBy: true,
            Cell: ({value}: FixType) => dayjs(value).format('ll'),
        },
        {
            Header: t('time'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            Cell: ({row: {original: {datetime_at}}}: FixType) => {
                return dayjs(datetime_at).format('HH:mm');
            },
        },
        {
            Header: t('indicators'),
            Cell: ({row: {original: {values}}}: FixType) => {
                const systRecordValue = values.find((i: RecordValue) => PRESSURE_FEATURE_SYST_ID === i.feature_id);
                const diastRecordValue = values.find((i: RecordValue) => PRESSURE_FEATURE_DIAST_ID === i.feature_id);

                const standardValues = values.filter((i: RecordValue) => ![PRESSURE_FEATURE_SYST_ID, PRESSURE_FEATURE_DIAST_ID].includes(i.feature_id))
                    .map((i: RecordValue) => {
                        const f = features[i.feature_id] || {};
                        const measure = i.measure ?? f.measure;

                        if (f.type === 'text' && i.value === '')
                            return null;

                        if (f.type !== 'boolean') {
                            let value = f.type === 'enum' ? t(i.value as string) : i.value;
                            if (i.feature_id === FEELING_FEATURE_ID) {
                                /** It works more stable than Math.round(x * 100) / 100 */
                                value = +(Math.round(+`${value ?? 0}e2`) + 'e-2');
                                const smile = <span style={{fontFamily: 'Glyphter', color: getFeelingColorByValue(value)}}>{getFeelingGlyphByValue(value)}</span>;
                                return <span key={i.id} style={{display: 'inline-flex', gap: '5px'}}>{t(f.name)}: {value} {smile}</span>;
                            } else {
                                return `${t(f.name)}: ${value}${measure !== '---' ? t(measure) : ''}`;
                            }
                        } else if (`${i.value}`.toLowerCase() === 'true') {
                            return t(f.name);
                        }

                        return null;
                    })
                    .filter(Boolean);

                if (systRecordValue && diastRecordValue) {
                    standardValues.unshift(`${t('pressure')}: ${systRecordValue.value} / ${diastRecordValue.value}`);
                }

                return standardValues.reduce((prev: FixType, curr: FixType) => {
                    if (typeof prev === 'string' && typeof curr === 'string') return [prev, curr].join(', ');
                    return [prev, ', ', curr];
                });
            },
        },
    ], [categories, features, i18n.language]);

    return (
        <>
            <Page title={t('entering_values_medical_card')}>
                <Panel
                    title={t('diary')}
                    loading={loading}
                    actions={<>
                        <PanelAction type={'add'} onClick={() => setOpenedRecord({} as FixType)} />
                        <PanelAction type={'delete'} disabled={selectedRecords.length === 0}
                            onClick={() => confirm(t('confirm_delete')) && deleteSelectedRecords()}
                        />
                    </>}
                >
                    <SortTable
                        columns={columns}
                        data={records as FixType}
                        onRowClick={setOpenedRecord}
                        onRowSelect={setSelectedRecords}
                        selectable={true}
                    />
                </Panel>
            </Page>

            <Modal
                isOpen={!!openedRecord}
                onClose={() => setOpenedRecord(undefined)}
                title={t('diary')}
            >
                <CardDiaryForm
                    record={openedRecord}
                    onCancel={() => setOpenedRecord(undefined)}
                    onSubmit={onSaveRecord}
                />
            </Modal>
        </>
    );
};

export default CardDiary;
