import styles from './style.module.css';

import Submenu from '../../component/Submenu';
import {Navigate, NavLink, Route, Routes} from 'react-router-dom';
import CardDoctor from './Doctor';
import CardDiary from './Diary';
import CardAnalysis from './Analysis';
import CardStudy from './Study';
import CardDrug from './Drug';
import {useTranslation} from 'react-i18next';
import CardAnamnesis from './Anamnesis';

const Card = () => {
    const { t } = useTranslation();

    return (
        <>
            <Submenu title={t('data_entry')}>
                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'anamnesis'}>{t('anamnesis')}</NavLink>

                <hr/>

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'doctor'}>{t('visit_a_doctor')}</NavLink>

                <hr/>

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'diary'}>{t('diary')}</NavLink>
                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'drug'}>{t('medication')}</NavLink>

                <hr/>

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'analysis'}>{t('analyses')}</NavLink>
                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'study'}>{t('diagnostic_tests')}</NavLink>
            </Submenu>

            <Routes>
                <Route index
                    element={<Navigate to="diary" replace />}
                />
                <Route path="doctor" element={<CardDoctor />} />
                <Route path="anamnesis" element={<CardAnamnesis />} />
                <Route path="diary" element={<CardDiary />} />
                <Route path="analysis" element={<CardAnalysis />} />
                <Route path="study" element={<CardStudy />} />
                <Route path="drug" element={<CardDrug />} />
            </Routes>
        </>
    );
};

export default Card;
