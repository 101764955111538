import styles from './style.module.css';

import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {clone, dateStringToMonth, genMonths} from '../../service/util';
import dayjs from 'dayjs';

interface ThisComponentProps {
    category: KmrCategory;
    data: WidgetAnalysisData[];
    from: string;
    to: string;
}

const AnalysisGrid = ({category, data, from = '2021-01-01', to = '2022-01-01'} : ThisComponentProps) => {
    const { t } = useTranslation();

    const dates: string[] = useMemo(() => genMonths(from, to), [from, to]);

    const features: CategoryFeature[] = useMemo(() => {
        const tmpCategory = clone(category);
        const usedFeatures = [...Array.from(new Set(data.map((i) => i.values.map((v) => v.feature_id)).flat()))];

        return tmpCategory.features
            .flat()
            .filter((i) => usedFeatures.includes(i.id));
    }, [category, data]);

    const mappedData: FixType = useMemo(() => {
        const tmp: FixType = features.reduce((a, i) => ({...a, [i.id]: {}}), {});

        data.forEach((i) => {
            const date = dateStringToMonth(i.record_at);

            i.values.forEach((v) => tmp[v.feature_id] ? tmp[v.feature_id][date] = v.value : '');
        });

        return tmp;
    }, [features, data]);


    return <div className={styles.wrap}>
        <div className={styles.label}>{t(category.name)}</div>
        <div className={styles.tableWrap}>
            <table className={styles.table}>
                <tbody>
                    {features.map((f) => <tr key={f.id}>
                        <th>{t(f.name)}</th>
                        {dates.map((d) => <td key={d}>{(mappedData[f.id] || {})[d]}</td>)}
                        <td />
                    </tr>)}
                </tbody>
                <tfoot>
                    <tr>
                        <th />
                        {dates.map((d, i) => {
                            const [year, month] = d.split('-');
                            return <td key={d} className={month === '12' ? styles.newYear : undefined}>
                                {(i === 0 || month === '01') && <span>{year}</span>}
                                <div>{dayjs(d).format('MMM')}</div>
                            </td>;
                        })}
                        <td />
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>;
};

export default AnalysisGrid;
