import styles from '../style.module.css';

import { useMemo } from 'react';
import { Data as PlotData, Layout } from 'plotly.js';
import Plot from 'react-plotly.js';

import { useAppSelector } from '../../../../module/redux/hooks';
import { genScatterDataset, genBoxDataset } from '../../../../service/plotUtils';

interface Props {
    category?: KmrCategory,
    data?: PlotData[],
    layout?: Partial<Layout>,
    advProps?: FixType
}

const DiaryPlot = ({category, data, layout, advProps}: Props) => {
    if (!data && category) {
        const state = useAppSelector((state) => state.diaryWidget);

        [data, layout] = useMemo(
            (): [PlotData[], Partial<Layout>] => {
                if (state.mode === 'box') {
                    return genBoxDataset(category, state.diary, state.from, state.to);
                } else {
                    return genScatterDataset(category, state.diary, state.from, state.to);
                }
            },
            [category, state.diary]
        );
    }

    return data?.some((i: FixType) => i.y.length > 0) ? <Plot
        data={data}
        layout={layout}
        config={{
            displayModeBar: false,
            scrollZoom: true
        }}
        useResizeHandler={true}
        className={styles.chart}
        {...advProps}
    /> : null;
};

export default DiaryPlot;