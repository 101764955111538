export enum Education {
    Primary = 'primary',
    Middle = 'middle',
    Secondary = 'secondary',
    Vocational = 'vocational'
}

export enum DrugMeasure {
    Mg = 'mg',
    Ml = 'ml',
    Custom = 'custom'
}

export enum DrugRoute {
    Oral = 'oral',
    Topical = 'topical',
    Custom = 'custom'
}

export enum DrugForm {
    Tablet = 'tablet',
    Spray = 'spray',
    Liquid = 'liquid'
}

export enum AnamnesisCategory {
    Chronic = 'chronic',
    Acute = 'acute'
}

export enum AnamnesisDisease {
    Infarct = 'infarct',
    Diabetes = 'diabetes',
    Smoking = 'smoking'
}

export enum CategoryName {
    Weight = 'weight',
    Height = 'height',
    Pressure = 'pressure',
    Pulse = 'pulse',
    Feeling = 'feeling',

    Smoke = 'smoke',
    Activity = 'activity',
    Mood = 'mood'
}

export enum FeatureName {
    Weight = 'weight',
    Height = 'height',
    Systolic = 'systolic',
    Diastolic = 'diastolic',
    Pulse = 'pulse',
    Arrhythmia = 'arrhythmia',
    Feeling = 'feeling',
    FeelingNote = 'feeling_note',

    CigarettesPerDay = 'cigarettes_per_day',
    CigarettesPerWeek = 'cigarettes_per_week',
    Walk = 'walk',
    Workout = 'workout',
}