export default {
    en: {
        localTranslation: {
            en: 'English',
            ru: 'Русский',
            data_entry: 'My med card',
            visit_a_doctor: 'Visit a doctor',
            diary: 'Diary',
            medication: 'Medication',
            analyses: 'Blood/Other Tests',
            analysis: 'Blood Test',
            documents: 'Documents',
            diagnostic_tests: 'Diagnostic tests',
            analysis_and_recommendations: 'Analysis and recommendations',
            diagnosis: 'Diagnosis',
            treatment: 'Treatment',
            overview_by_disease: 'Overview by disease',
            heart_condition: 'Heart condition',
            diabetes: 'Diabetes',
            my_office: 'My office',
            working_with_contacts: 'Working with contacts',
            digitization: 'Digitization',
            settings: 'Settings',
            access_control: 'Access control',
            my_token: 'My current token',
            send_access: 'To send access, copy your token OR scan the QR code. Description of what needs to be done next and what the result will be.',
            copy_token: 'Copy your token to send access',
            or: 'OR',
            scan_qr: 'Scan the QR code to submit access',
            access_to_other: 'Your access to other cards',
            request_access: 'Request access',
            access_request: 'Access request:',
            reject_request: 'Reject request',
            accept_request: 'Access request',
            terminate_access: 'Terminate access',
            request_sent: 'Request sent',
            access_to_your_card: 'Access to your card',
            give_access: 'Give access',
            user_id: 'User ID',
            note: 'Note',
            personal_data: 'Personal data',
            name: 'name',
            gender: 'gender',
            male: 'male',
            female: 'female',
            age: 'age',
            country: 'country',
            city: 'city',
            save_changes: 'Save Changes',
            security: 'Security',
            current_password: 'Current password',
            new_password: 'New password',
            confirm_new_password: 'Repeat new password',
            home: {
                title: 'KMR - Home page',
                risks: {
                    title: 'Based on current data',
                    diabetes: 'Risk of diabetes',
                    hypertension: 'Risk of hypertension',
                    why: 'Why?',
                    low: 'low',
                    below_average: 'below average',
                    average: 'average',
                    above_average: 'above average',
                    high: 'high'
                },
                programs: {
                    title: 'Examination programs',
                    diabetes: 'Diabetes',
                    diabetes_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
                    pressure: 'Pressure',
                    pressure_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
                    vision: 'Vision',
                    vision_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
                    stress: 'Stress',
                    stress_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
                    show: 'Show'
                }
            },
            date: 'Date',
            time: 'Time',
            indicators: 'Indicators',
            pressure: 'Pressure',
            entering_values_medical_card: 'Entering values for the medical card',
            confirm_delete: 'Delete exactly?',
            medications: 'Medications',
            type: 'Type',
            doctor: 'Doctor',
            brief_diagnosis: 'Brief diagnosis',
            complete_diagnosis: 'Complete diagnosis',
            good: 'Good',
            bad: 'Bad',
            arrhythmia: 'Arrhythmia',
            pulse: 'Pulse',
            pulse_and_arrhythmia: 'Pulse and arrhythmia',
            pulse_top: 'Pulse - top',
            pulse_bottom: 'Pulse - bottom',
            pulse_trend: 'Pulse trend',
            systolic: 'Systolic',
            systolic_top: 'Systolic - top',
            systolic_bottom: 'Systolic - bottom',
            systolic_trend: 'Systolic trend',
            diastolic: 'Diastolic',
            diastolic_top: 'Diastolic - top',
            diastolic_bottom: 'Diastolic - bottom',
            diastolic_trend: 'Diastolic trend',
            tick_systolic: 'systolic',
            tick_diastolic: 'diastolic',
            statistics: 'Statistics',
            report_header_title: 'Your family will be able to monitor your health',
            click_to_more: 'Click to find out more',
            mood: 'Mood',
            no_data: 'No data',
            data_not_available: 'Data not available',
            medicine: 'Medicine',
            dosage_mg: 'dosage (mg)',
            quantity: 'quantity',
            cancel: 'Cancel',
            save_close: 'Save and close',
            medical_institution: 'Medical institution',
            detailing: 'Detailing',
            test: 'Test',
            result: 'Result',
            value: 'Value',
            norm: 'Norm',
            dynamics_year: 'Dynamics for the year',
            choose_a_test: 'Choose a test',
            choose_a_type: 'Choose a type',
            choose_type_of_analysis: 'choose the type of analysis',
            add_block: 'Add block',
            add_file: 'Add file',
            registration: 'Registration',
            username: 'Username',
            email: 'Email',
            password: 'Password',
            start: 'Start',
            register: 'Register',
            forgot_password: 'Forgot your password?',
            week: 'Week',
            month: 'Month',
            six_months: '6 Months',
            morning: 'morning',
            afternoon: 'afternoon',
            evening: 'evening',
            night: 'night',
            mg: 'mg',
            mg_dl: 'mg/dL',
            mmol_l: 'mmol/L',
            nmol_l: 'nmol/L',
            pmol_l: 'pmol/L',
            mU_l: 'mU/L',
            'nmol/l': 'nmol/L',
            'pmol/l': 'pmol/L',
            'mU/l': 'mU/L',
            yyyy_mm: 'YYYY-MM',
            kmr_title: 'Keep Med Record - Your personal medical assistant',
            contacts: 'Contacts',
            about: 'About',
            faq: 'FAQ',
            welcome_user: 'Welcome, {{user}}',
            entrance: 'Entrance',
            sign_in: 'Sign in',
            success: 'Success!',
            new_password_info: 'A new password has been sent to you! With it, you can create a password yourself!',
            new_password_info_secondary: 'A new password will be sent to your email!',
            reset_password: 'Reset password',
            reset: 'Reset',
            patient_diagnoses: 'Patient\'s diagnoses',
            chat: 'Chat',
            my_contacts: 'My contacts',
            anamnesis: 'Entering data',
            information: 'Information',
            category: 'Category',
            acute: 'Acute',
            chronic: 'Chronic',
            diagnosis_date: 'Diagnosis date',
            disease: 'Disease',
            disease_active: 'Active disease',
            disease_inactive: 'Not diagnosed',
            cigarettes_per_day: 'Cigarettes per day',
            cigarettes_per_week: 'Cigarettes per week',
            activity: 'Activity',
            walk: 'Walk',
            workout: 'Workout',
            h: 'H',
            education: 'Education',
            primary: 'High School',
            middle: 'High School Diploma/GED',
            secondary: 'College',
            vocational: 'Degree',
            yes: 'Yes',
            no: 'No',
            messages: {
                announcement_title: 'Announcement!',
                completed_title: 'Completed successfully!',
                warning_title: 'Warning!',
                error_title: 'Error!',
                error: 'Something went wrong... Try again later',
                success: {
                    create: 'Record created successfully',
                    update: 'Record updated successfully',
                    delete: 'Records deleted successfully',
                    password_update: 'Password changed successfully',
                    token: 'Token copied to memory',
                    profile_update: 'Profile data updated',
                    request: 'Wait for invite approval'
                },
                warning: {
                    browser: 'Your browser does not support in-memory data',
                    data_correct: 'Check the correctness of the data',
                    anamnesis_category: 'Category is required!',
                    drug_required: 'Drug is required!',
                    disease_required: 'Brief diagnosis is required!'
                }
            },
            feeling: {
                bad: 'Bad',
                normal: 'Normal',
                excellent: 'Excellent'
            },
            feeling_note: 'Add comment',
            tick_good: 'Good',
            tick_bad: 'Bad',
            logout: 'Logout',
            drug_form: 'Dosage form',
            tablet: 'Tablets',
            tablet_one: 'tablet',
            tablet_other: 'tablets',
            spray: 'Spray',
            liquid: 'Liquid',
            dosage: 'Dosage',
            active_ingredient: 'Active ingredient',
            no_results: 'No results...',
            median: 'Median',
            link_expired: 'This link has expired.',
            link_invalid: 'Oops, something went wrong!',
            link_try_again: 'Please try to generate a new link.'
        }
    },
    ru: {
        localTranslation: {
            en: 'English',
            ru: 'Русский',
            data_entry: 'Моя мед карта',
            visit_a_doctor: 'Посещение врача',
            diary: 'Дневник',
            medication: 'Прием лекарств',
            analyses: 'Анализы',
            analysis: 'Анализ',
            documents: 'Документы',
            diagnostic_tests: 'Инструмент. исследования',
            analysis_and_recommendations: 'Анализ и рекоммендации',
            diagnosis: 'Диагнозы',
            treatment: 'Лечение',
            overview_by_disease: 'Обзор по заболеванию',
            heart_condition: 'Сердечное состояние',
            diabetes: 'Диабет',
            my_office: 'Мой офис',
            working_with_contacts: 'Работа с контактами',
            digitization: 'Оцифровка',
            settings: 'Настройки',
            access_control: 'Управление доступом',
            my_token: 'Мой текущий токен',
            send_access: 'Для того, чтобы отправить доступ скопируйте свой токен ИЛИ просканируйте QR-код. Описание того, что нужно делать дальше и какой результат будет.',
            copy_token: 'Скопируйте свой токен, чтобы отправить доступ',
            or: 'ИЛИ',
            scan_qr: 'Просканируйте QR-код, чтобы отправить доступ',
            access_to_other: 'Ваш доступ к другим картам',
            request_access: 'Запросить доступ',
            access_request: 'Запрос на доступ:',
            request_sent: 'Запрос отправлен',
            reject_request: 'Отклонить запрос',
            accept_request: 'Принять доступ',
            terminate_access: 'Прекратить доступ',
            access_to_your_card: 'Доступ к вашей карте',
            give_access: 'Дать доступ',
            user_id: 'Идентификатор пользователя',
            note: 'Примечание',
            personal_data: 'Личные данные',
            name: 'имя',
            gender: 'пол',
            male: 'муж',
            female: 'жен',
            age: 'возраст',
            country: 'страна',
            city: 'город',
            save_changes: 'Сохранить изменения',
            security: 'Безопасность',
            current_password: 'Текущий пароль',
            new_password: 'Новый пароль',
            confirm_new_password: 'Повторить новый пароль',
            home: {
                title: 'KMR - Главная страница',
                risks: {
                    title: 'На основании текущих данных',
                    diabetes: 'Риск диабета',
                    hypertension: 'Риск гипертонии',
                    why: 'Почему?',
                    low: 'низкий',
                    below_average: 'ниже среднего',
                    average: 'средний',
                    above_average: 'выше среднего',
                    high: 'высокий'
                },
                programs: {
                    title: 'Программы обследования',
                    diabetes: 'Диабет',
                    diabetes_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
                    pressure: 'Давление',
                    pressure_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
                    vision: 'Зрение',
                    vision_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
                    stress: 'Стресс',
                    stress_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
                    show: 'Смотреть'
                }
            },
            date: 'Дата',
            time: 'Время',
            indicators: 'Показатели',
            pressure: 'Давление',
            entering_values_medical_card: 'Ввод значений для мед карты',
            confirm_delete: 'Точно удалить?',
            medications: 'Лекарства',
            type: 'Тип',
            doctor: 'Врач',
            brief_diagnosis: 'Краткий диагноз',
            complete_diagnosis: 'Полный диагноз',
            good: 'Хорошо',
            bad: 'Плохо',
            arrhythmia: 'Аритмия',
            pulse: 'Пульс',
            pulse_and_arrhythmia: 'Пульс и аритмия',
            pulse_top: 'Пульс - верхнее',
            pulse_bottom: 'Пульс - нижнее',
            pulse_trend: 'Пульс тренд',
            systolic: 'Систолическое',
            systolic_top: 'Систолическое - верхнее',
            systolic_bottom: 'Систолическое - нижнее',
            systolic_trend: 'Систолическое тренд',
            diastolic: 'Диастолическое',
            diastolic_top: 'Диастолическое - верхнее',
            diastolic_bottom: 'Диастолическое - нижнее',
            diastolic_trend: 'Диастолическое тренд',
            tick_systolic: 'систол.',
            tick_diastolic: 'диастол.',
            statistics: 'Статистика',
            report_header_title: 'Родные смогут следить за вашим здоровьем',
            click_to_more: 'Нажмите, чтобы узнать больше',
            mood: 'Настроение',
            no_data: 'данные отсутствуют',
            data_not_available: 'Данные недоступны',
            medicine: 'Лекарство',
            dosage_mg: 'дозировка (мг)',
            quantity: 'количество',
            cancel: 'Отмена',
            save_close: 'Сохранить и закрыть',
            medical_institution: 'мед. учреждение',
            detailing: 'Детализация',
            test: 'Тест',
            result: 'Результат',
            value: 'Значение',
            norm: 'Норма',
            dynamics_year: 'Динамика за год',
            choose_a_test: 'Выберите тест',
            choose_a_type: 'Выберите тип',
            choose_type_of_analysis: 'выбрать тип анализа',
            add_block: 'Добавить блок',
            add_file: 'Добавить файл',
            registration: 'Регистрация',
            username: 'Имя пользователя',
            email: 'Электронный адрес',
            password: 'Пароль',
            start: 'Начать',
            register: 'Зарегистрироваться',
            forgot_password: 'Забыли пароль?',
            week: 'Неделя',
            month: 'Месяц',
            six_months: '6 Месяцев',
            morning: 'утро',
            afternoon: 'день',
            evening: 'вечер',
            night: 'ночь',
            mg: 'мг',
            mg_dl: 'мг/дл',
            mmol_l: 'ммоль/л',
            nmol_l: 'нмоль/л',
            pmol_l: 'пмоль/л',
            mu_l: 'мЕд/л',
            'nmol/l': 'нмоль/л',
            'pmol/l': 'пмоль/л',
            'mU/l': 'мЕд/л',
            yyyy_mm: 'ГГГГ-ММ',
            kmr_title: 'Keep Med Record - Ваш персональный медицинский ассистент',
            contacts: 'Контакты',
            about: 'О нас',
            faq: 'FAQ',
            welcome_user: 'Добро пожаловать, {{user}}',
            entrance: 'Вход',
            sign_in: 'Войти',
            success: 'Успех!',
            new_password_info: 'Вам выслан новый пароль! С его помощью вы можете создать пароль самостоятельно!',
            new_password_info_secondary: 'Новый пароль будет отправлен на ваш email!',
            reset_password: 'Cброс пароля',
            reset: 'Сбросить',
            patient_diagnoses: 'Диагнозы пациента',
            chat: 'Чат',
            my_contacts: 'Мои контакты',
            anamnesis: 'Внесение данных',
            information: 'Информация',
            category: 'Категория',
            acute: 'Острый',
            chronic: 'Хронический',
            diagnosis_date: 'Дата диагноза',
            disease: 'Заболевание',
            disease_active: 'Действующее заболевание',
            disease_inactive: 'Не диагностировано',
            cigarettes_per_day: 'Сигарет в день',
            cigarettes_per_week: 'Сигарет в неделю',
            activity: 'Активность',
            walk: 'Прогулка',
            workout: 'Тренировка',
            h: 'Час',
            education: 'Образование',
            primary: 'Старшая школа',
            middle: 'Диплом о среднем образовании',
            secondary: 'Колледж',
            vocational: 'Научная степень',
            yes: 'Да',
            no: 'Нет',
            messages: {
                announcement_title: 'Информационное сообщение!',
                completed_title: 'Выполнено успешно!',
                warning_title: 'Предупреждение!',
                error_title: 'Ошибка!',
                error: 'Что-то пошло не так... Попробуйте позже',
                success: {
                    create: 'Запись успешно создана',
                    update: 'Запись успешно обновлена',
                    delete: 'Записи удалены',
                    password_update: 'Пароль успешно изменён',
                    token: 'Токен скопирован в память',
                    profile_update: 'Данные профиля обновлены',
                    request: 'Ожидайте принятие инвайта'
                },
                warning: {
                    browser: 'Ваш браузер не поддерживает копирование данных в память',
                    data_correct: 'Проверьте корректность данных',
                    anamnesis_category: 'Выберите категорию!',
                    drug_required: 'Выберите лекарство из поисковой выдачи!',
                    disease_required: 'Выберите диагноз из поисковой выдачи!'
                }
            },
            feeling: {
                bad: 'Плохое',
                normal: 'Нормальное',
                excellent: 'Отличное'
            },
            feeling_note: 'Добавить комментарий',
            tick_good: 'Хорошо',
            tick_bad: 'Плохо',
            logout: 'Выйти',
            drug_form: 'Лекарственная форма',
            tablet: 'Таблетки',
            tablet_interval: '(1)[таблетка];(2-4)[таблетки];(5-inf)[таблеток];',
            spray: 'Спрей',
            liquid: 'Жидкость',
            dosage: 'Дозировка',
            active_ingredient: 'Действующее вещество',
            no_results: 'Нет результатов...',
            median: 'Медиана',
            link_expired: 'Срок действия сылки истек.',
            link_invalid: 'Упс, что-то пошло не так!',
            link_try_again: 'Пожалуйста, попробуйте сгенерировать новую ссылку.'
        }
    }
};